<template>
  <div>
    <b-modal
      ref="deleteDefaultSlots"
      id="deleteDefaultSlots"
      :no-close-on-backdrop="loading > 0"
      :hide-header-close="loading > 0"
      @hide="modalHidden"
      :title="$t('calendarModule.deleteDefaultSlots.title')"
      :hide-footer="true"
    >
      <div v-if="loading">
        <div class="d-flex justify-content-between mx-5 mt-3 mb-5">
          <div class="spinner-border text-success align-self-center loader-lg"/>
        </div>
      </div>
      <div v-else-if="loadingError" class="cm-loading-error">
        {{ $t("calendarModule.errors.loadingError") }}
      </div>
      <div v-else-if="finished">
        <b-icon-check /> {{ $t("calendarModule.deleteDefaultSlots.finished") }}
      </div>
      <div v-else>
        <p>
          {{
            $t("calendarModule.deleteDefaultSlots.amountInfo").replace(
              "{amount}",
              defaultSlots.length
            )
          }}
        </p>
        <b-button size="xs" variant="danger" @click="() => confirmed()">
          {{ $t("calendarModule.deleteDefaultSlots.confirm") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as cmApi from "@/store/modules/calendarModule/calendarModule.api";

export default {
  name: "DeleteDefaultSlots",
  props: ["defaultSlots"],
  data() {
    return {
      cmApi,
      loading: 0,
      loadingError: false,
      finished: false
    };
  },
  methods: {
    slotsChanged() {
      this.$emit("slotsChanged");
    },
    modalHidden() {
      this.$emit("hidden");
    },
    setSearchIdentifier(slotId, identifier) {
      this.searchIdentifiers[slotId] = identifier;
    },
    confirmed() {
      for (const slot of this.defaultSlots) {
        this.loading++;
        cmApi.defaultSlot
          .remove(slot._id)
          .then(res => {
            this.loading--;
            this.loadingError = false;
            if (!this.loading) {
              this.slotsChanged();
            }
          })
          .catch(e => {
            this.loading--;
            this.loadingError = false;
          });
      }
    }
  },
  mounted() {
    this.$refs["deleteDefaultSlots"].show();
  }
};
</script>
